class ValidationService {
  static isValidEmail(email) {
    const re = /^[a-zA-Z0-9._-]{1,}(?:\+[a-zA-Z0-9._-]*)?@(?:[a-zA-Z0-9-]{2,}\.)+(?:[a-zA-Z]{2,}\.)*(?:[a-zA-Z]{2,})$/;
    return re.test(email);
  }

  static isEmpty(string) {
    return string.trim().length === 0;
  }

  static isAbsoluteURL(string) {
    const filter = /https?:\/\/[^\s$.?#].[^\s]*$/gm;
    return filter.test(string);
  }
}

module.exports = ValidationService;
